// @flow
import { css } from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  Twitter,
  Facebook,
  Instagram,
  ThePropertyOmbudsman,
  TradingStandards,
} from "../../icons";
import { media } from "../../media";

const footer = css`
  background-color: ${({ theme }) => theme.palette.sand40};
  clear: both;
`;

const content = css`
  max-width: 1140px;
  margin: 0px auto;
  padding: 0px 20px 40px 20px;
  ${media.desktop`
    padding: 80px 20px 80px 20px;
  `}
`;

const linkGroupWrapper = css`
  vertical-align: top;
  display: inline-block;
  width: calc(50% - 10px);
  margin-top: 40px;

  &:nth-of-type(2n - 1) {
    margin-right: 20px;
  }

  ${media.tablet`
    width: calc(50% - 20px);
    &:nth-of-type(2n - 1) {
      margin-right: 40px;
    }
  `}
  ${media.desktop`
    margin: 0px 40px 0px 0px;
    width: calc(25% - 30px);
    &:last-of-type {
      margin-right: 0px;
    }
  `}
`;

const basicText = css`
  font-size: 12px;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.hague80};
  margin: 0px;
`;

const linkTextStyle = css`
  ${basicText}
  text-decoration: none;
`;

const liStyle = css`
  list-style-type: none;
  margin: 0px;
  margin-top: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const ulStyle = css`
  margin: 20px 0px 0px;
  padding: 0px;
`;

const headingStyle = css`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague};
  line-height: 28px;
  margin: 0px;
`;

const iconStyle = css`
  height: 21px;
  width: 21px;
  display: inline-block;
  margin-right: 20px;
`;

const regulatoryBodiesLogoStyle = css`
  height: 32px;
  display: inline-block;
  margin-right: 10px;
`;

const inlineText = css`
  ${linkTextStyle}
  margin-right: 20px;
  padding: 5px 0px;
  white-space: nowrap;
  display: inline-block;
`;

const inlineLink = css`
  ${inlineText}
  &:hover {
    text-decoration: underline;
  }
`;

const contactDetailsWrapper = css`
  margin-top: 40px;
  display: flex;
  align-items: center;
  ${media.desktop`
    margin-top: 0px;
    display: block;
    width: calc(100% / 3);
  `}
`;

const phoneNumberWrapper = css`
  height: 100%;
  width: 50%;
  padding-right: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.hague};
  text-decoration: none;
  ${media.desktop`
    width: 100%;
    height: auto;
    display: block;
    padding: 0;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  `}
`;

const phoneIconStyle = css`
  margin-right: 10px;
  ${media.desktop`
    margin-right: 15px;
    font-size: 25px;
    position: relative;
    top: 2px;
  `}
`;

const iconsWrapper = css`
  width: 50%;
  box-sizing: border-box;
  padding-left: 10px;
  display: inline-block;
  ${media.tablet`
    padding-left: 20px;
  `}
  ${media.desktop`
    display: block;
    padding: none;
    width: 100%;
    padding: 0;
    margin-top: 20px;
  `}
`;

const regulatoryBodiesWrapper = css`
  display: block;
  width: 100%;
  padding: 0;
  margin-top: 20px;
`;

const inlineLinksWrapper = css`
  margin-top: 40px;
  ${media.desktop`
    width: calc(100% / 3);
    margin-right: 20px;
  `};
`;

const topSectionWrapper = css`
  ${media.desktop`
    display: flex;
    flex-direction: row-reverse;
  `}
`;

const bottomSectionWrapper = css`
  ${media.desktop`
    display: flex;
  `}
`;

const lineDivider = css`
  margin: 40px 0px 0px;
  border: none;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.hague20};
  ${media.desktop`
  `}
`;

const legalParagraph = css`
  ${basicText}
  margin-top: 20px;
`;

const legalParagraphGroup = css`
  ${media.tablet`
    display: inline-block;
    width: calc(50% - 20px);
    &:first-of-type {
      margin-right: 40px;
    }
    vertical-align: top;
  `}
`;

const menusWrapper = css`
  ${media.desktop`
    width: calc(100% / 3 * 2);
  `}
`;

const legalStuffWrapper = css`
  margin-top: 20px;
  ${media.desktop`
    margin-top: 40px;
    width: calc(100% / 3 * 2);
  `};
`;

const FooterLinkUnorderedList = ({ title, children }) => (
  <div css={linkGroupWrapper}>
    <div css={headingStyle}>{title}</div>
    <ul css={ulStyle}>{children}</ul>
  </div>
);

const FooterLinkListItem = ({ children, to, target = "_self" }) => (
  <li css={liStyle}>
    {to.startsWith("http") ? (
      <a href={to} target={target} css={linkTextStyle}>
        {children}
      </a>
    ) : (
      <Link to={to} css={linkTextStyle}>
        {children}
      </Link>
    )}
  </li>
);

export const Footer = () => (
  <footer css={footer}>
    <div css={content}>
      <div css={topSectionWrapper}>
        <div css={menusWrapper}>
          <FooterLinkUnorderedList title="Sell with Nested">
            <FooterLinkListItem to="/selling">Selling</FooterLinkListItem>
            <FooterLinkListItem to="/lettings">Letting</FooterLinkListItem>
            <FooterLinkListItem to="/for-sale">Buying</FooterLinkListItem>
          </FooterLinkUnorderedList>

          <FooterLinkUnorderedList title="Nested app">
            <FooterLinkListItem to="https://account.nested.com">
              Login
            </FooterLinkListItem>
            <FooterLinkListItem to="https://nested.com/ios" target="_blank">
              iOS app
            </FooterLinkListItem>
            <FooterLinkListItem to="https://nested.com/android" target="_blank">
              Android app
            </FooterLinkListItem>
          </FooterLinkUnorderedList>

          <FooterLinkUnorderedList title="Tools and advice">
            <FooterLinkListItem to="/blog">Blog</FooterLinkListItem>
          </FooterLinkUnorderedList>

          <FooterLinkUnorderedList title="About Nested">
            <FooterLinkListItem to="/partnerships">
              Partner careers
            </FooterLinkListItem>
            <FooterLinkListItem to="/careers">HQ careers</FooterLinkListItem>
            <FooterLinkListItem to="/technology">
              Technology careers
            </FooterLinkListItem>
            <FooterLinkListItem to="https://uk.trustpilot.com/review/www.nested.com">
              Reviews
            </FooterLinkListItem>
          </FooterLinkUnorderedList>
        </div>
        <div css={contactDetailsWrapper}>
          <a href="tel:02038580695" css={phoneNumberWrapper}>
            <FontAwesomeIcon css={phoneIconStyle} icon={faPhone} />
            020 3858 0695
          </a>
          <div css={iconsWrapper}>
            <a href="https://en-gb.facebook.com/nestedestateagents/">
              <Facebook css={iconStyle} />
            </a>
            <a href="https://twitter.com/nested?lang=en">
              <Twitter css={iconStyle} />
            </a>
            <a href="https://www.instagram.com/nested_estate_agents/?hl=en">
              <Instagram css={iconStyle} />
            </a>
          </div>
        </div>
      </div>
      <hr css={lineDivider} />
      <div css={bottomSectionWrapper}>
        <div css={inlineLinksWrapper}>
          <a css={inlineLink} href="/privacy-policy">
            Privacy policy
          </a>
          <a css={inlineLink} href="/terms-and-conditions">
            Terms
          </a>
          <a css={inlineLink} href="/complaints">
            Complaints
          </a>
        </div>
        <div css={legalStuffWrapper}>
          <div css={headingStyle}>The legal stuff</div>
          <div>
            <div css={legalParagraphGroup}>
              <p css={legalParagraph}>
                &copy; Nested 2 Ltd, company registration number 14923549.
              </p>
              <div css={regulatoryBodiesWrapper}>
                <a href="http://www.tpos.co.uk">
                  <ThePropertyOmbudsman css={regulatoryBodiesLogoStyle} />
                </a>
                <a href="https://www.tradingstandards.uk">
                  <TradingStandards css={regulatoryBodiesLogoStyle} />
                </a>
              </div>
              <p css={legalParagraph}>
                Nested is a member of The Property Ombudsman.
              </p>
            </div>
            <div css={legalParagraphGroup}>
              <p css={legalParagraph}>
                Nested 2 Ltd,
                <br />
                12-18 Theobalds Road, London, WC1X 8PL
                <br />
                <a href="mailto:help@nested.com">help@nested.com</a>
              </p>
              <p css={legalParagraph}>
                By continuing your visit to this site, you agree to the use of
                cookies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
