// @flow
export const theme = {
  /*
   * The full colour scheme can be found here https://www.figma.com/file/Imvp5ogBklykTftWskTvCO/Nest-Library?node-id=922%3A394
   *
   * Legacy colours are colours that we are currently using but that we are not planning to use moving forwards.
   * Live components using legacy colours have just not yet been re-designed. Please use the colours found in the full colour
   * scheme, and add colours / move them out of "legacy" accordingly.
   */
  palette: {
    // Correct colours according to Figma
    hague150: "#052938",
    hague100: "#024255",
    hague70: "#4E7A88",
    hague40: "#9AB3BB",
    hague20: "#CCD9DD",
    terracotta150: "#FF6750",
    terracotta100: "#FF8770",
    pink300: "#FF6F81",
    pink150: "#FFC1CB",
    pink100: "#FFD4DB",
    pink70: "#FFE0E5",
    pink40: "#FFEEF1",
    pink20: "#FFF6F8",
    sand150: "#EBE1D6",
    sand100: "#F2EBE3",
    sand70: "#F5F1EB",
    sand40: "#FAF7F4",
    sand20: "#FCFBF9",
    blue170: "#1773C5",
    blue150: "#2D88D9",
    blue100: "#47A6E5",
    blue70: "#7EC0EC",
    blue40: "#B5DBF5",
    blue20: "#DAEDFA",
    blue10: "#EDF6FC",
    yellow150: "#FFAA19",
    yellow100: "#FFC22B",
    yellow70: "#FFD46A",
    yellow40: "#FFE7AA",
    yellow20: "#FFF3D5",
    yellow10: "#FFF9EA",
    green150: "#0D734F",
    green100: "#19926F",
    // Legacy colours: (see above documentation)
    blue15: "rgba(71, 166, 229, 0.15)",
    blue90: "rgba(71, 166, 229, 0.9)", // #59AEE7
    blue500: "#1871CF",
    lightBlue: "rgba(216, 237, 251, 1.0)", // #D8EDFB
    hague10: "rgba(10, 66, 84, 0.1)", // #E6ECED
    hague30: "rgba(10, 66, 84, 0.3)", // #9DB3BB
    hague50: "rgba(10, 66, 84, 0.5)", // #84A0A9
    hague80: "rgba(10, 66, 84, 0.8)", // #3B6876
    hague90: "rgba(10, 66, 84, 0.9)", // #225465
    hague: "rgba(10, 66, 84, 1.0)", // #0A4254 deprecated - use hague100 instead
    hague130: "rgba(7, 51, 67, 1)", // #073343
    orange10: "rgba(255, 193, 0, 0.1)",
    orange20: "rgba(255, 193, 0, 0.2)",
    orange40: "rgba(255, 152, 10, 0.4)",
    orange60: "rgba(255, 152, 10, 0.6)",
    orange70: "rgba(255, 152, 10, 0.7)", // #FFD07F
    orange: "rgba(255, 170, 25, 1)", // #FFAA19
    orange500: "rgba(255, 152, 10, 1)", // #FF980A
    graphicalPink: "#FFB3BE",
    pink500: "#FF92A1",
    sand80: "rgba(242, 235, 227, 0.8)", // #F5EFE9
    sand500: "#D9C7B3", // #D9C7B3
    terracotta20: "rgba(255, 135, 122, 0.2)", // #FF8770
    terracotta40: "rgba(255, 135, 122, 0.4)", // #FFCFC6
    terracotta60: "rgba(255, 135, 122, 0.6)", // #FFB7A9
    terracotta80: "rgba(255, 135, 122, 0.8)", // #FF9F8D
    terracotta90: "rgba(255, 135, 122, 0.9)", // #FF937E
    terracotta500: "#FE6A50",
    yellow80: "rgba(255, 194, 43, 0.8)", // #FFCE55
    yellow500: "#FFAB19",
    green: "rgba(61, 173, 144, 1.0)", // #3dad90"
    green80: "rgba(61, 173, 144, 0.8)", // #3cac90
    green60: "rgba(61, 173, 144, 0.6)", // #3cad8f
    green40: "rgba(61, 173, 144, 0.4)", // #3cac8e
    green20: "rgba(61, 173, 144, 0.2)", // #3caf91
    green500: "#3DAE91",
    enquiryGreen: "#259271",
    warningOrange: "rgba(255, 231, 226)", // #ffe7e2
    attentionRed: "#ff4d37",
    attentionRedDark: "#cf3c28",
  },
  fonts: {
    euclid: "'Euclid Circular B', sans-serif",
    striverText: "StriverText, serif",
    handwritten: "'Reenie Beanie', cursive",
  },
  breakpoints: {
    tablet: 480,
    desktop: 860,
    maxDesktop: 1180,
  },
};
